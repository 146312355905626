import { waitAuth } from "~/utils/middleware";

import { Sub } from "~/types/subscriptions";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Sub.Type.DrivePro);

    const isCanceled = __subscription().getIsDisabled(Sub.Type.DrivePro);
    if (!__subscription().has(Sub.Type.DrivePro) && !isCanceled) {
        log("❌ Has no Drive pro");
        return navigateTo("/");
    }

    log("✅ Has Drive pro or Drive pro is canceled");
});
